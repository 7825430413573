<!--
 * @Author: huanglin.like@qq.com
 * @LastEditTime: 2024-01-12 14:33:40
-->
<template>
  <navBar>
    <div class="edition-center" v-loading="pageLoading">
      <template v-if="goodsInfo.id">
        <div class="evaluate-container"></div>
        <div class="evaluate-goods">
          <card-horizontal
            v-if="goodsInfo.id"
            :moreVisible="true"
            :goodsInfo="goodsInfo"
            :detailInfo="true"
          ></card-horizontal>
        </div>
        <div class="flex items-center mt-20px mb-14px">
          <div class="label">Reseña:</div>
          <div class="text ml-15px">{{ score }}</div>
        </div>
        <div class="flex items-start">
          <div class="label">evaluación:</div>
          <div class="ml-15px">
            <div class="text">{{ goodsInfo.comment.content }}</div>
            <div class="image_wrapper">
              <img
                v-for="(item, index) in goodsInfo.comment.imagesUrls"
                :key="index"
                :src="item | filterPicture"
                class="image"
              />
            </div>
          </div>
        </div>
        <div class="divisionLine"></div>
        <div>
          <div class="text">
            Respuesta{{ goodsInfo.comment.hasReplyCount || '' }}
          </div>

          <div>
            <div
              v-for="(item, index) in commentList"
              :key="index"
              class="comment_item"
            >
              <div class="flex items-center">
                <div class="label_2">{{ item.memberDetail.name ||  'servicio cliente' }}：</div>
                <div class="text2 ml-6px">{{ item.content }}</div>
              </div>
              <div class="comment_time">{{ item.createdTime }}</div>
            </div>
          </div>
          <div class="input_wrapper">
            <el-input
              type="textarea"
              placeholder="Respuesta"
              :autosize="{ minRows: 4, maxRows: 10 }"
              v-model="replyContent"
            />
            <div class="btn_wrapper" @click="handleReply">Respuesta</div>
          </div>
        </div>
      </template>
    </div>
  </navBar>
</template>

<script>
import navBar from '@/components/navBar/index.vue'
import cardHorizontal from '@/components/product/card-horizontal.vue'
export default {
  components: {
    navBar,
    cardHorizontal
  },
  data() {
    return {
      pageLoading: false,
      goodsInfo: {},
      score: 0,
      content: '',
      replyContent: '',
      commentList: []
    }
  },
  mounted() {
    this.getOrderDetails()
    console.log('this.$route :>> ', this.$route)
  },
  methods: {
    async setRead() {
      await this.$axios.post(`/order/orderGoodsComment/clearReadFlag/${this.goodsInfo.id}`)
    },
    async getCommentList() {
      this.pageLoading = true
      const {
        data: { result }
      } = await this.$axios.post(
        `/order/orderGoodsComment/${this.goodsInfo.id}`
      )
      if(!this.commentList.length) this.setRead()
      this.commentList = result.splice(1)
      console.log("🚀 ~ getCommentList ~ this.commentList:", this.commentList)
      this.pageLoading = false
    },
    async getOrderDetails() {
      this.pageLoading = true
      try {
        const {
          data: { result }
        } = await this.$axios.post('childOrder/detail/' + this.$route.query.id)
        console.log('🚀 ~ getOrderDetails ~ result:', result)
        this.score = result.customerSatisfaction
        result.orderGoodsList.forEach((item) => {
          if (item.id === this.$route.query.orderGoodsId) {
            this.goodsInfo = item
            this.goodsInfo.comment.imagesUrls = JSON.parse(
              item.comment.imagesUrls
            )
            this.getCommentList()
          }
        })
        console.log(
          '🚀 ~ result.orderGoodsList.forEach ~ this.goodsInfo:',
          this.goodsInfo
        )

        this.pageLoading = false
      } catch (error) {
        this.pageLoading = false
      }
    },
    async handleReply() {
      if (!this.replyContent) {
        this.$message.error('请填写回复内容')
        return
      }
      this.pageLoading = true
      try {
        const params = {
          pid: this.goodsInfo.comment.id,
          content: this.replyContent
        }
        await this.$axios.post('order/commentOrder/reply', params)
        this.getCommentList()
        this.goodsInfo.comment.hasReplyCount += 1
        this.replyContent = ''
        this.pageLoading = false
      } catch (error) {
        this.pageLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;
  min-height: 500px;
}

.evaluate-container {
  margin: 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  .evaluate-goods {
    padding: 10px 20px;
    margin-top: 20px;
    background: #f9fbf9;
  }

  .input-container {
    margin: 40px 0;
    .title {
      margin-bottom: 10px;
    }
    .upload-box {
      display: flex;
      .add {
        width: 120px;
        margin: 20px 20px 0 0;
        img {
          width: 100%;
        }
      }

      .upload,
      .imgVisible {
        display: inline-block;
        width: 120px;
        margin: 10px 20px 0 0;
        img {
          display: block;
          width: 100%;
          height: 120px;

          object-fit: cover;
        }
      }

      // 删除图标
      .col-delete {
        position: relative;
        .el-icon-error {
          position: absolute;
          top: -7px;
          right: -7px;
          display: none;
          color: #999;
          font-size: 20px;
          cursor: pointer;
          &:hover {
            color: #333;
          }
        }
        &:hover {
          .el-icon-error {
            display: block;
          }
        }
      }
    }

    ::v-deep .el-textarea__inner {
      font-size: 16px;
    }
  }

  .submit {
    text-align: center;
  }
}
.label {
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #081b05;
  line-height: 30px;
}

.label_2 {
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #081b05;
  line-height: 28px;
}

.text {
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #606060;
  line-height: 30px;
}

.text2 {
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #606060;
  line-height: 28px;
}

.comment_item {
  margin-bottom: 45px;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 30px;
  }
}
.comment_time {
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #606060;
  line-height: 28px;
  margin-top: 14px;
}

.divisionLine {
  height: 1px;
  border-top: 1px solid #eaeaea;
  width: 1200px;
  margin: 30px 0;
}
.image_wrapper {
  margin-top: 30px;
  .image {
    width: 156px;
    height: 156px;
    object-fit: cover;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.input_wrapper {
  width: 1166px;
  min-height: 156px;
  background: #f9fbf9;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  margin-top: 18px;
  .btn_wrapper {
    height: 39px;
    background: #45b035;
    border-radius: 20px;
    padding: 8px 21px;
    box-sizing: border-box;
    color: white;

    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.satisfaction_wrapper {
  .el-input__inner {
    width: 460px;
    height: 56px;
    background: #f9fbf9;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 1px solid #e7e7e7;
  }
}

.input_wrapper {
  .el-textarea__inner {
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
}
</style>
